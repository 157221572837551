/*
* Dépendance : 
* - jquery.mousewheel.js
*/
(function() {
    "use strict";

    var Tracker, Grabber;

    // Private Helpers Function
    function horiFix() {
        var distX = parseFloat(this.element.style.left);

        if (distX > 0) {
            this.element.style.left = 0;
        } else if (distX < this.maxDistX) {
            this.element.style.left = this.maxDistX + "px";
        }
    }

    function vertFix() {
        var distY = parseFloat(this.element.style.top);

        if (distY > 0) {
            this.element.style.top = 0;
        } else if (distY < this.maxDistY) {
            this.element.style.top = this.maxDistY + "px";
        }
    }

    function resolveMovementX(evt) {
        var pageX = evt.pageX || evt.changedTouches[0].pageX;

        this.mv_originX = parseFloat(this.element.style.left) || 0;
        this.mv_offsetX = this.mv_originX + (pageX - this.touch_origX);

        this.touch_origX = pageX;

        if (this.mv_offsetX <= 0 && this.mv_offsetX >= this.maxDistX) {
            this.mv_percentX = (this.mv_offsetX / this.maxDistX) * 100;

            if (this.tracker.X !== null) {
                this.tracker.X.cursor.style.left =
                    this.tracker.X.max * (this.mv_percentX / 100) + "px";
            }

            this.element.style.left = this.mv_offsetX + "px";
        }
    }

    function resolveMovementY(evt) {
        var pageY = evt.pageY || evt.changedTouches[0].pageY;

        this.mv_originY = parseFloat(this.element.style.top) || 0;
        this.mv_offsetY = this.mv_originY + (pageY - this.touch_origY);

        this.touch_origY = pageY;

        if (this.mv_offsetY <= 0 && this.mv_offsetY >= this.maxDistY) {
            this.mv_percentY = (this.mv_offsetY / this.maxDistY) * 100;

            if (this.tracker.Y !== null) {
                this.tracker.Y.cursor.style.top =
                    this.tracker.Y.max * (this.mv_percentY / 100) + "px";
            }

            this.element.style.top = this.mv_offsetY + "px";
        }
    }

    Tracker = function(dir, elm) {
        var par_h = elm.element.parentElement.offsetHeight,
            elm_h = elm.element.offsetHeight,
            tcr_h = Math.round(elm.element.parentElement.offsetHeight * 0.95),
            tck_h = Math.round(tcr_h * (par_h / elm_h)),
            par_w = elm.element.parentElement.offsetWidth,
            elm_w = elm.element.offsetWidth,
            tcr_w = Math.round(elm.element.parentElement.offsetWidth * 0.95),
            tck_w = Math.round(tcr_w * (par_w / elm_w));

        this.track = document.createElement("div");
        this.cursor = document.createElement("div");

        this.track.classList.add("tracker");
        this.track.classList.add(dir);

        this.cursor.classList.add("track");

        if (dir === "X") {
            this.max = tcr_w - tck_w - 4;

            this.cursor.style.height = tck_w + "px";

            this.track.style.width = tcr_w + "px";
            this.track.style.marginLeft = -Math.round(tcr_w * 0.5) + "px";
        } else {
            this.max = tcr_h - tck_h - 4;

            this.cursor.style.height = tck_h + "px";

            this.track.style.height = tcr_h + "px";
            this.track.style.marginTop = -Math.round(tcr_h * 0.5) + "px";
        }

        this.track.appendChild(this.cursor);

        elm.element.parentElement.appendChild(this.track);

        elm.tracker[dir] = this;
    };

    // Public Grabber Prototype
    Grabber = function(elm) {
        this.element = elm;
        this.tracker = {X: null, Y: null};

        this.is_grabbed = false;

        this.touch_origX = 0;

        this.maxDistX = elm.parentElement.offsetWidth - elm.offsetWidth;

        this.mv_originX = 0;
        this.mv_offsetX = 0;

        this.mv_percentX = 0;

        this.touch_origY = 0;

        this.maxDistY = elm.parentElement.offsetHeight - elm.offsetHeight;

        this.mv_originY = 0;
        this.mv_offsetY = 0;

        this.mv_percentY = 0;

        return this.init();
    };

    Grabber.prototype.init = function() {
        var that = this;

        this.element.style.position = "relative";

        if (this.maxDistX < 0) {
            this.tracker.X = new Tracker("X", this);
        }

        if (this.maxDistY < 0) {
            this.tracker.Y = new Tracker("Y", this);

            $(this.element).on(
                "mousewheel",
                function(evt) {
                    var init_top = parseFloat(
                            this.element.style.top.replace(/[^\d\.\-]/g, "")
                        ),
                        delta_top = evt.deltaFactor * evt.deltaY;

                    evt.preventDefault();

                    if (isNaN(init_top)) {
                        init_top = 0;
                    }

                    this.maxDistY =
                        this.element.parentElement.offsetHeight -
                        this.element.offsetHeight;

                    this.mv_offsetY = Math.max(
                        init_top + delta_top,
                        this.maxDistY
                    );
                    this.mv_offsetY = Math.min(0, this.mv_offsetY);

                    this.mv_percentY = (this.mv_offsetY / this.maxDistY) * 100;

                    this.tracker.Y.cursor.style.top =
                        this.tracker.Y.max * (this.mv_percentY / 100) + "px";

                    this.element.style.top = this.mv_offsetY + "px";
                }.bind(this)
            );
        }

        if (
            !(
                Grabber.prototype.mouseD &&
                Grabber.prototype.mouseU &&
                Grabber.prototype.mouseM
            )
        ) {
            this.mouseD = function(evt) {
                Grabber.prototype.grab.call(that, evt);
                return false;
            };

            this.mouseU = function(evt) {
                Grabber.prototype.release.call(that, evt);
            };

            this.mouseM = function(evt) {
                Grabber.prototype.move.call(that, evt);
            };
        }

        this.element.addEventListener("mousedown", this.mouseD);
        this.element.addEventListener("touchstart", this.mouseD);

        window.addEventListener("mouseup", that.mouseU);
        window.addEventListener("touchend", that.mouseU);

        window.addEventListener("mousemove", that.mouseM);
        window.addEventListener("touchmove", that.mouseM);

        return that;
    };

    Grabber.prototype.grab = function(evt) {
        var pageX = evt.pageX || evt.changedTouches[0].pageX;
        var pageY = evt.pageY || evt.changedTouches[0].pageY;
        var closest_event_holder = $(evt.target).closest("[onclick]");

        if (evt.target.tagName !== "SELECT") {
            evt.preventDefault();

            this.is_grabbed = true;
            this.has_moved = false;

            if ($(this.element).find(closest_event_holder).length > 0) {
                this.callback = closest_event_holder.attr("onclick");
            } else {
                this.callback = false;
            }

            this.touch_origX = pageX;
            this.touch_origY = pageY;

            this.element.classList.add("grabbed");
        }

        return this;
    };

    Grabber.prototype.release = function(evt) {
        if (this.is_grabbed) {
            evt.preventDefault();

            if (!this.has_moved) {
                eval(this.callback);
            }

            this.is_grabbed = false;

            horiFix.call(this);
            vertFix.call(this);

            this.element.classList.remove("grabbed");
        }

        return this;
    };

    Grabber.prototype.move = function(evt) {
        if (this.is_grabbed) {
            this.has_moved = true;

            resolveMovementX.call(this, evt);
            resolveMovementY.call(this, evt);
        }

        return this;
    };

    Grabber.prototype.destroy = function() {
        var that = this;

        if (this.tracker.X !== null) {
            this.element.parentElement.removeChild(this.tracker.X.track);
            this.tracker.X.track = null;
        }

        if (this.tracker.Y !== null) {
            this.element.parentElement.removeChild(this.tracker.Y.track);
            this.tracker.Y.track = null;
        }

        this.element.style.top = 0;

        this.element.removeEventListener("mousedown", this.mouseD);
        this.element.removeEventListener("touchstart", this.mouseD);

        window.removeEventListener("mouseup", that.mouseU);
        window.removeEventListener("touchend", that.mouseU);

        window.removeEventListener("mousemove", that.mouseM);
        window.removeEventListener("touchmove", that.mouseM);

        return false;
    };

    window.Grabber = Grabber;
})();