function showsendfriendmodal(product_id, path_web, type) {
    var elname = type === "look_basket" ? "modbox" : "sendfriendbox";
    var actualposition = document.documentElement.scrollTop;

    if (type !== 'look_basket') {
        var psnok = document.getElementById('product_sendfriend_nok');
        var psfok = document.getElementById('product_sendfriend_ok');
        if (psnok) {
            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }
        if (psfok) {
            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        prodGetProductDisplay(product_id, path_web, type);

        openMultiShad("sendfriendbox");
        $("#shad_abox").addClass('actif');

    } else {
        show_lightbox(elname);
        $(".loader", "#bloc_add_basket").hide();
    }
}

function prodGetProductDisplay(id, path_web, type) {
    if (typeof type == "undefined") {
        var response = ajax_file(
            path_web + "ajax_get_infos_send_friend.php?product_id=" + id
        );
        document.getElementById("sendfriend_prodinfos").innerHTML = response;
    } else {
        if (type == "look") {
            $("#titre_popup").html(Translator.translate('sendfriend_title_look'));
            $("#friend_form").show();
            $("#btn_popup_panier").hide();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else if (type == "look_basket") {
            $("#titre_popup").html("Vous venez d'ajouter le look suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else {
            $("#titre_popup").html("Vous venez d'ajouter le produit suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();
        }
    }

    if (
        navigator.userAgent.indexOf("MSIE") != -1 &&
        navigator.userAgent.indexOf("6.") != -1
    ) {
        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "hidden";
        }
    }
}

$(function () {
    $('#shad_abox').on("click", function() {
        closeMultiShad();
        $('.lightbox.actif').removeClass('actif');
    });
 });


/**
    * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
*/
function lightboxAlertStock() {

     var $taille_id = $("#tailleProd").val(),
         $couleur_id = $("#couleurProd").val();

     if ($taille_id == '') {
         if (!$('#sizeerror').is(':visible')) {
             $('#sizeerror').slideDown();
         }
         return;
     } else if ($couleur_id == '') {
         alert(translate('js_error_couleur'));
         return;
     }

     var isFormVisible = $('.bloc_add_alert_form').is(':visible');
     var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
     var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

     if (isFormVisible || isMessageVisible || isErrorVisible) {

         if (isFormVisible) {

             $('.bloc_add_alert_form').slideUp('fast');
         }

         if (isMessageVisible) {

             $('.bloc_add_alert_confirmation').slideUp('fast');
         }

         if (isErrorVisible) {

             $('.bloc_add_alert_erreur').slideUp('fast');
         }
     } else {

         $('.bloc_add_alert_form').slideDown('fast');
     }
}

$('#bloc_add_alert #btn_add_alert').click(function(){
  $(this).hide();
});

$('.bloc_add_alert_confirmation button').click(function(){
  $('#bloc_add_alert #btn_add_alert').show();
});

/* DESCRIPTION */

$(document).ready(function(){    
    $('.thumbslide .see_more').on('click', function(e) {   
        $('.thumbslide').removeClass('active');      
        $(this).parents('.thumbslide').addClass('active');            
    });    
});

if ($("body").hasClass("product_page")) {
    $(".product_eclat_attributes").appendTo(".price_and_wishlist .item_price");

    setTimeout(function() {
        $(".product_eclat_attributes").addClass('visible');
    }, 100);
}