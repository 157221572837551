$(function() {

    var bodyPage = document.querySelector('body');
    var siteHeader = document.getElementById("site_head_wrap");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {
        var scrollTop = $(document).scrollTop();

        if (siteHeader.classList.contains("sticky")) {
            if (scrollTop === 0) {
                siteHeader.classList.remove("sticky");
                shade.classList.remove("sticky");
                bodyPage.classList.remove('stickyHeader');
            }
        } else {
            if (scrollTop !== 0) {
                siteHeader.classList.add("sticky");
                shade.classList.add("sticky");
                bodyPage.classList.add('stickyHeader');
            }
        }
    }

    $(window).on("scroll", onWinScroll);

    onWinScroll();

    /* Pre header swiper */
    if ($('.promo_swiper').length && ($('.promo_swiper .swiper-slide').length > 1)) {
        var preheader_swiper = new Swiper('.promo_swiper', {
            autoplay: true,
            slidesPerView : 1,
            loop: true,
            speed: 2000,
            effect: 'fade',
        })
    }

    $(document).on("click", ".button", btnLoaderTrigger);

    // changes spacing at the top of tg module depending if title is set or not
    var homeTgModule = $('.home-module.simple-gondole');
    if (homeTgModule.length) {
        homeTgModule.each( function() {
            if ($(this).find('.home-module-title').is(':empty')) {
                $(this).addClass('no_title');
            }
        });
    }

    recalculateHeaderTop();
});

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

/**
 * Buttons loader management
 */
function btnLoaderTrigger(evt) {
    "use strict";

    var element = $(this);
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        evt.preventDefault();

        loader.removeAttr("style").parent().addClass("loading");
        loader.removeClass("cache");
        loader.addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
            loader.addClass("cache");
            loader.removeClass("loading");
        }, 1200);

        var href = $(this).attr("href");
        if (href && href != '#' && href != 'javascript:void(0)') {
            window.location.href = href;
        }
    }
}

function openMultiShad(id) {

    if ($('.lightbox.actif').length) {
        $('.lightbox.actif').removeClass('actif');
    }

    $("#" + id).addClass('actif');

    if (id !== 'shad_menu') {
        if (!$("#shad").hasClass('actif')) {
            $("#shad").addClass('actif');
        }

        $("#shad").on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id === 'lightboxOrderGiftCard' || id === 'sendwishlistbox') {
        $('#shad').addClass('actif');
    }

    if (id === 'popup_savoir_plus') {
        $("#popup_savoir_plus").css("display", "block");
        $('#shad').css('display', 'block');
    }
}

function closeMultiShad(id) {
    $('body').css('position','');
    $("#" + id).removeClass('actif');
    $("#shad").removeClass('actif');
    $(".shad").removeClass('actif').removeClass('white');
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {

    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    if(this.elements.namedItem('qteProd')  ) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v1 = $('.express_response').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : (is_wishlist || is_achat_express_v1) ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + 'ajax_get_product_regroup_ref.php',
        data : data,
        success : function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;

                if (data.type_page == 'product') {

                    var html_main = $(stripCombo(datas.html_main));
                    var product_visuals = $('#productVisuals', html_main).html();
                    var product_infos = $('#productInfos', html_main).html();
                    var product_characs = $('.pageProduit__characteristics', html_main).html();
                    var cms_page = $('.pageProduit__publications', html_main).html();
                    var price = $('.productInfos__price', html_main).html();
                    var size = $('.productInfos__size', html_main).html();

                    // Photo et information
                    $('#productVisuals').html(product_visuals);
                    $('#productInfos').html(product_infos);
                    $(".pageProduit__characteristics").html(product_characs);
                    $(".pageProduit__publications").html(cms_page);
                    $('.productInfos__price').html(price);
                    $('.productInfos__size').html(size);

                    // On scroll en haut de la page dans le cas d'un regroupement
                    $('html, body').animate({
                        scrollTop: $(window).scrollTop() + 1
                    }, 500);
                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {

                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                var productSwiper = new Swiper(".productSwiper", {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

                $('.thumbslide .see_more').on('click', function(e) {
                    $('.thumbslide').removeClass('active');
                    $(this).parents('.thumbslide').addClass('active');
                });

            }else{
                $( '#btn_error' ).fadeIn();
            }
        },
        complete : function () {

            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {

                if ($("#visual_scroller .swiper-slide").length < 4) {
                    $('.more_vis').addClass('few_pics');
                }

                if ($("#jqzoom .swiper-slide").length > 0) {
                    var main_productVisu = new Swiper('#jqzoom', {
                        slidesPerView: 1,
                        //loop: true,
                        navigation: {
                            nextEl: '.mainVisNext',
                            prevEl: '.mainVisPrev',
                        },
                        pagination: {
                            el: ".swiper-pagination",
                        },
                        lazy: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true
                    });

                    var stillImageProduct = new Swiper('.stillImageProduct', {
                        slidesPerView: 1,
                        noSwiping: true,
                        lazy: true,
                        loadPrevNext: true,
                    });

                    main_productVisu.controller.control = stillImageProduct;
                }

                if ($("#thumbs_scroller").length > 0) {
                    var zoomThumbs = new Swiper('#thumbs_scroller', {
                        slidesPerView: 'auto',
                        spaceBetween: 14,
                        direction: 'vertical',
                        freeMode: true,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                    });

                    $("#thumbs_scroller .swiper-slide img").on('click touch', function(){
                        //Update thumbs class
                        $("#thumbs_scroller .swiper-slide").each(function (index) {
                            $(this).removeClass('active_slide');
                        });

                        $(this).parent().addClass('active_slide');

                        //Update large image
                        var src = $(this).attr('src');
                        src = src.replace("medium", "large");
                        src = src.replace("mini", "large");
                        $('img.img_zoom').attr('src', src);
                    });
                }

                if ($("#zoom_box_wrapper .swiper-slide").length > 0) {
                    var main_productVisu = new Swiper('#zoom_box_wrapper', {
                        effect: 'fade',
                        slidesPerView: 1,
                        thumbs: {
                            swiper: zoomThumbs
                        },
                        preloadImages: false,
                        lazy: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true

                    });

                }

                function updateSrc(path_web, dest) {
                    var tmp_string_large = document.getElementById("img_large").src;
                    tmp_string = tmp_string_large.replace(/medium/, "large");

                    document
                        .getElementById("img_large")
                        .setAttribute(
                            "onclick",
                            "zoomImg('" + tmp_string + "','" + path_web + "')"
                        );
                    document.getElementById("loupe").href =
                        "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
                    $(".zoomLens").css({backgroundImage: 'url("' + tmp_string_large + '")'});

                    if (dest == "vue") {
                        var i = 0;
                        var trouve = false;
                        while (i < tab_js.length && !trouve) {
                            trouve =
                                tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                                tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
                            i++;
                        }

                        cpt_img = i - 1;
                    }
                }

                function updateZoomedImg(src) {
                    $(".main_vis img", this).attr("src", src);
                }

                /* Fin reinitialisation sliders visus */

                var more_vis = $('.more_vis');
                var grabber;

                more_vis.each(function () {

                    var jqzoom = $(this).next('#jqzoom');

                    if (jqzoom.length > 0) {

                        $(this).height(jqzoom.height());

                        if (document.getElementById('visual_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('visual_scroller'), true, false);
                            }, 250);
                        }
                    }

                    var zoom_box_wrapper = $(this).next('#zoom_box_wrapper');

                    if (zoom_box_wrapper.length > 0) {

                        $(this).height(zoom_box_wrapper.height());

                        if (document.getElementById('thumbs_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('thumbs_scroller'), true, false);
                            }, 250);
                        }
                    }

                });

                var productImgsWrapper = $(".productImgsWrapper");
                var productDescrWrapper = $(".productDescrWrapper");

                $(document).on("scroll", function () {

                    var scrollTop = $(document).scrollTop();
                    var newTop = (scrollTop - (productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()));

                    if(scrollTop >= productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()) {

                        productDescrWrapper.css("top", "-" + newTop + "px");
                    } else {

                        productDescrWrapper.css("top", "auto");
                    }
                });

                $(window).on('resize', function () {

                    more_vis.each(function () {

                        var jqzoom = $(this).next('#jqzoom');

                        if (jqzoom.length > 0) {

                            $(this).height(jqzoom.height());
                        }

                        var jqzoom = $(this).next('#zoom_box_wrapper');

                        if (jqzoom.length > 0) {

                            $(this).height(zoom_box_wrapper.height());
                        }

                    });
                });

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 500);
            }, 250);

            heads.each(function(){
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            getAvailableQuantity.call($('form.productInfos__form', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist);

            if($(".productSwiper").length) {
                var productSwiper = new Swiper(".productSwiper", {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });
            }

            //Simulates scroll (2px) to load images
            window.scrollTo(0, $(window).scrollTop()+2);
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {

    var control, prod_id, from_page;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

        if (is_assoc_product) {
            from_page = 'assoc';
        } else {
            from_page = 'product';
        }

        if (is_regroup) {

            loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id);
        } else {
            // On regarde si le produit est dejà dans la wishlist
            // checkProductInWishlist(prod_id, itm_rr_id);

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    $('#ligne_pointure_' + product_id + regroup_ref_id).show();
    $('#ligne_couleur_' + product_id + regroup_ref_id).hide();
    $("#prod_info_" + product_id + regroup_ref_id + " .bloc_add_color").hide();
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.getValue('produit_id');
    var elem = isAchatExpressV2 ? '_' + productId + itm_rr_id : '';
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from  = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    var productId = false;
    if (this.getAttribute('data-product-id')) {
        productId = this.getAttribute('data-product-id');
    }
    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        if (productId) {
            loadProduct.call(form, productId, 'taille' , '', itm_rr_id);
        } else {
            // Depending on the color we see if the product is available, do this only in product page
            getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), from, event, itm_rr_id, isWishList, productRef);
        }
    }

}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    if ($("#tailleProd" + elem).length) {
        var size_id = $("#tailleProd" + i).val();
    } else {
        var size_id = $("[name='tailleProd']:checked").val();
    }
    var plnb = form ? form.getValue('produit_lot_nb') : 0;

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    $('#sizeerror').addClass('hided');

    var data = {
        idprod: $('#produit_id').val(),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {

        data.suff = form.getValue('produit_id');
    }

    if (plnb) {

        data.produit_lot_nb = plnb;
    }
    $.ajax({
        url: path_relative_root + "ajax_liste_qte.php",
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            if (res.stock_tampon == 0) {
                $(".eclat_product_nouveaute_fiche_produit").show();

                $("#bloc_add_alert" + elem).show();
                $("#prod_info" + elem + " .rollover_left").hide();

                if (!is_achat_express && is_wishlist == false) {
                    $("#bloc_add_basket").hide();
                    if (!$(".w-alertestock-form").length) {
                        new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                    }
                }

                $('#ligne_qte').append($('.restock_date'));
                $('.taunt_text').css('display', 'none');
            } else {
                if (res.stock_tampon > 0 && res.stock_tampon <= 5) {
                    if (res.stock_tampon == 1) {
                        $('.taunt_text').html(Translator.translate('last_taunt', res.stock_tampon));
                    } else {
                        $('.taunt_text').html(Translator.translate('last_taunts', res.stock_tampon));
                    }
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                if (is_achat_express) {
                    waitingAddToBasket(event, form, '', itm_rr_id);

                    if (from == 'product') {
                        // Show bloc colors when we add product to basket
                        $('#ligne_pointure' + elem).hide();
                        $('#ligne_couleur' + elem).show();
                        $("#prod_info" + elem + " .bloc_add_color").show();
                    } else if (from == 'lookbook') {
                        closeMultiShad('lightbox_achat_express_look');
                    }

                } else {
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $("#bloc_add_alert").hide();
                    $("#bloc_add_basket").show();
                    $("#btn_add_cart").prop('disabled', false);
                    $("#bloc_add_basket").removeClass('inactiv');
                }
            };
        }
    });
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {

            loader.removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + create_link('ajax_show_basket'));

        $("#cart_top").html(response_basket);

        var count_bask = $('#cart_top .cart').attr('data-cart');

        if (count_bask > 2) {
            $("#cart_top .cart_product_listing").overlayScrollbars({});
        }

        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = $("#couleurProd" + i);
    if ($("#tailleProd" + i).length) {
        var tailles = $("#tailleProd" + i);
    } else {
        var tailles = $("[name='tailleProd']:checked");
    }
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != "none") idCouleur = couleurs.val();

        if (tailles && tailles.val() != "none") idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(
            path_web +
            "ajax_upd_panier.php?prixu=" +
            prixU +
            "&idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&qte=" +
            qte +
            "&panierId=" +
            paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                            ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(translate("product_no_stock_2"));
                return false;
            }
        }
        // dataleyr V2 add Cart
        if (array_response[5] !== undefined) {
            pushIntoDatalayer(array_response[5]);
        }
        return array_response;
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/
function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

function createDatalayer(prod_id, url) {
    if (productlist_gondole){
        for ( var i = 0 ; i < productlist_gondole.length; i++) {
            prod = productlist_gondole[i];
            if (prod.id == prod_id){
                window.list = prod['list'];
                delete prod['list'];
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list':list},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': 'function() { document.location = url}',
                });
            }

        }
    }
}

/***** PROD ASSOCS DATA LAYERS *****/

function dataLayer_associated_product () {

    if (window.$('.otherProduct')) {

        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }
            var prod = productObj;
            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

$(document).on("click", ".thumblnk", function(evt) {
    var id_pannel = this.getAttribute("data-pannel");
    var thumbnav = $(this).closest(".thumbnav");
    var thumbcnt = thumbnav.next(".thumbcnt");

    evt.preventDefault();

    thumbnav
        .find(".thumblnk")
        .not(".thumblnk[data-pannel=" + id_pannel + "]")
        .removeClass("current");
    thumbcnt
        .find(".thumbslide")
        .not(".thumbslide[data-pannel=" + id_pannel + "]")
        .removeClass("current");

    thumbnav
        .find(".thumblnk[data-pannel=" + id_pannel + "]")
        .addClass("current");
    thumbcnt
        .find(".thumbslide[data-pannel=" + id_pannel + "]")
        .addClass("current");
});

$(document).on('click','.object__item, .object__title',function() {
    sessionStorage.setItem('clickedMenu', 1);
    sessionStorage.setItem('DlPosition', $(this).data('position'));
});

// Alters item quantity in cart
function changeQte(type) {

    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link("order_basket"),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                // Cart slider initialization
                var sliderCart = $('#main_cart_wrapper #basketAssocWrapper');

                if (sliderCart.length) {

                    sliderCart.remove();
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                var $label_basket_line_qte = Translator.translate('quantity');
                $( "<label>" + $label_basket_line_qte + "</label>" ).insertBefore( ".product_quantity_change .qteWrapper" );
            },
            complete : function() {

                var total = $('#cart_top .smallCart__title').attr('data-cart'),
                cart = translate('mon_panier'),
                text;
                if(total <= 1){
                    text = translate('one_product');
                } else {
                    text = translate('2_product');
                };
                $('.cart_main_title.basket').html(cart +' '+ ' <span class="nb_products">(' + total + ' ' + text + ')</span>');

                // Update Cart elements after ajax reload
                updateCartElements();
            }
        });
    }
}

function newshowmodal(products, type, root, id_produit, is_not_produit) {
    topCartAppear();

    window.setTimeout(function () {
        topCartDesappear();
    }, 2000);
}

function topCartAppear() {
    var show_top_cart_parent = $('.headerNav .headerNav__item#cart_top');
    
    $('#cart_list.smallCart__list').overlayScrollbars({});
    
    recalculateHeaderTop();
    show_top_cart_parent.addClass("active");
    $('#shad_menu').addClass("actif");
    $('#site_head_wrap').addClass('is-filled');
}

function topCartDesappear() {
    var show_top_cart_parent = $('.headerNav .headerNav__item');

    show_top_cart_parent.removeClass("active");
    $('#shad_menu').removeClass("actif");
    $('#site_head_wrap').removeClass('is-filled');
}

/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + "livraison-ajax.php",
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.error(e);
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function(elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function() {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }
}

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Show bloc colors
    $('#ligne_couleur_' + elem).show();

    // Hide bloc size
    $('#ligne_pointure_' + elem).hide();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('.wishlistBtn').show();
    $('.bloc_add_color').show();
}

/*
 * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function() {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
                if( is_disabled )  {
                    size_box.addClass('disabled');
                }
                else {
                    size_box.removeClass('disabled');
                }
            }
        }
    }
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {

        setTimeout(function(){loader.removeAttr("style").parent().removeClass("loading")}, 200);
    }
}

// FERMER EN SAVOIR PLUS CONNEXION
function close_popup(elem) {
    var $modbox = $("#" + elem);
    $("#" + elem).removeClass('actif');
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    $modbox.css({display: "none"});
    //bodyScrollUnlock();
}

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');

        subLogin.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                            fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

// Recalculate header and rollover positions when no preheader
function recalculateHeaderTop() {
    var $headerTable = $("#site_head_wrap");
    if ($headerTable.length > 0) {
        var $headerElem = $headerTable.first();
        var $preheader = $("#header_mention_id");
    
        if ($preheader.length === 0) {
            var $headeHome = $("body.homepage");
            if ($headeHome.length > 0) {
                $headeHome.css("marginTop", "0");
            }
        } else {
            $preheader.addClass('pre-header-activat');
        }

        var headerHeight = $headerElem[0].getBoundingClientRect().height;

        if ($("header .hoverable").length) {
            $('.headerSubMenu').css("top", headerHeight + "px");
            $('.headerRollover').css("top", headerHeight + "px");
        }
    
        $("header .hoverable").on("mouseenter", function() {
            $('.headerSubMenu').css("top", headerHeight + "px");
            $('.headerRollover').css("top", headerHeight + "px");
        });
    }
}